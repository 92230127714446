import { Controller } from '@hotwired/stimulus';
export default class extends Controller {
  static targets = ['text'];

  connect() {
    this.mixLetters();
  }

  mixLetters() {
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let interval = null;

    this.textTargets.forEach((textElement) => {
      let iteration = 0;

      clearInterval(interval);

      interval = setInterval(() => {
        textElement.innerText = textElement.innerText
          .split('')
          .map((letter, index) => {
            if (index < iteration) {
              return textElement.dataset.value[index];
            }

            return letters[Math.floor(Math.random() * 26)];
          })
          .join('');

        if (iteration >= textElement.dataset.value.length) {
          clearInterval(interval);
        }

        iteration += 1 / 3;
      }, 40);
    });
  }
}
